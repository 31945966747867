import { type HtmlLegacyRendererProps, replaceImgWithAmpImg } from "@inferno/renderer-shared-core";
import { useHashAnchorLinks } from "@inferno/renderer-shared-ui";
import { inject } from "mobx-react";
import { useRef } from "react";
import "./HtmlEmbed.style.scss";

export const HtmlEmbedLegacy = inject("store")(({ html: __html, store, amp }: HtmlLegacyRendererProps) => {
  if (!store) {
    return null;
  }

  if (amp && __html.includes("<img")) {
    __html = replaceImgWithAmpImg(__html);
  }

  const htmlRef: React.RefObject<HTMLDivElement> = useRef(null);

  useHashAnchorLinks(htmlRef, store);

  return <div ref={htmlRef} className="component-embed-html" dangerouslySetInnerHTML={{ __html }} />;
});

export default HtmlEmbedLegacy;
